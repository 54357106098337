define("discourse/plugins/swapd-support-tickets/discourse/components/step-three", ["exports", "discourse-common/utils/decorators", "@ember/utils", "@ember/component", "@ember/template"], function (_exports, _decorators, _utils, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    title: I18n.t("support_tickets.start.title.steps.three.main"),
    subTitle(seller_or_buyer) {
      const target = seller_or_buyer == "seller" ? "buyer" : "seller";
      return (0, _template.htmlSafe)(I18n.t("support_tickets.start.title.steps.three.sub", {
        target
      }));
    },
    nextDisabled(price) {
      if (isNaN(price) || (0, _utils.isBlank)(price) || parseFloat(price) <= 0) return true;
      return false;
    },
    actions: {
      next() {
        if (this.get("nextDisabled")) return;
        this.set("step", 4);
      }
    }
  }, [["method", "subTitle", [(0, _decorators.default)("model.seller_or_buyer")]], ["method", "nextDisabled", [(0, _decorators.default)("model.price")]]]));
});