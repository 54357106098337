define("discourse/plugins/swapd-support-tickets/discourse/components/step-zero", ["exports", "discourse-common/utils/decorators", "discourse/lib/utilities", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/lib/url", "@ember/utils", "@ember/string", "@ember/component", "@ember/template", "@ember/service", "discourse/plugins/swapd-support-tickets/discourse/components/modal/send-invite-modal", "@ember/application"], function (_exports, _decorators, _utilities, _ajax, _ajaxError, _url, _utils, _string, _component, _template, _service, _sendInviteModal, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
    - if the user is logged in then the user will be asked for the other parties username and _handleLoggedIn() will be used.
    - if the user is not logged in then the user will be asked for email addresses and _handleAnonymous() will be used.
      - if both email addresses are not found then _showInvites will be called and the user is asked if they want to send invites
      - if one email address is found then _showLoginOrRegister is called and the user is asked "is it you? yes -> log in no -> register"
      - if both email addresses are found then _showLogin is called and the user is prompted to login
  */
  var _default = _exports.default = _component.default.extend(dt7948.p({
    dialog: (0, _service.inject)(),
    logoURL: "/plugins/swapd-support-tickets/logo.png",
    loading: false,
    updateUsername() {
      this.set('model.target_username', this.get('model.target_usernames')[0] || null);
    },
    title(currentUser, args) {
      const suffix = currentUser ? "logged_in" : "anonymous";
      return (0, _template.htmlSafe)(I18n.t(`support_tickets.start.title.steps.zero.${suffix}`, args));
    },
    titleArgs(currentUser) {
      return currentUser ? {
        inbox_url: `/u/${currentUser.username}/messages`
      } : {};
    },
    nextDisabled(loading, currentUser, target_username, seller_email, buyer_email) {
      if (loading) return true;
      if (currentUser) return (0, _utils.isBlank)(target_username);
      return (0, _utils.isBlank)(seller_email) || !(0, _utilities.emailValid)(seller_email) || (0, _utils.isBlank)(buyer_email) || !(0, _utilities.emailValid)(buyer_email);
    },
    actions: {
      next() {
        if (this.get("nextDisabled")) return;
        this.set("loading", true);
        this.get("currentUser") ? this._handleLoggedIn() : this._handleAnonymous();
      },
      showInvite() {
        this._showInvite();
      }
    },
    _handleLoggedIn() {
      const username = this.get("model.target_username");
      (0, _ajax.ajax)("/start/begin", {
        type: "POST",
        data: {
          username
        }
      }).then(result => {
        if (result.error) {
          this.dialog.alert({
            message: I18n.t(result.error)
          });
        } else if (result.url) {
          _url.default.routeTo(result.url);
        } else if (result.user) {
          this._goToNextStep();
        } else {
          this._showInvite(true);
        }
      }).catch(_ajaxError.popupAjaxError).finally(() => this.set("loading", false));
    },
    _goToNextStep() {
      this.set("model.starter", this.currentUser.get("username"));
      this.set("step", 1);
    },
    _showInvite(userNotFound) {
      let modal = (0, _application.getOwner)(this).lookup("service:modal");
      modal.show(_sendInviteModal.default, {
        model: {
          userNotFound: userNotFound
        }
      });
    },
    _handleAnonymous() {
      (0, _ajax.ajax)("/start/begin", {
        type: "POST",
        data: {
          emails: [this.get("model.seller_email"), this.get("model.buyer_email")]
        }
      }).then(result => {
        const methods = (0, _string.w)("_showInvites _showLoginOrRegister _showLogin");
        const method = methods[result.emails.length];
        this[method](result.emails);
      }).catch(_ajaxError.popupAjaxError).finally(() => this.set("loading", false));
    },
    _sendInvites(seller, buyer) {
      (0, _ajax.ajax)("/start/invites", {
        type: "POST",
        data: {
          seller,
          buyer
        }
      }).then(result => {
        const dialog = (0, _application.getOwner)(this).lookup("service:dialog");
        dialog.alert({
          message: (0, _template.htmlSafe)(I18n.t("support_tickets.start.invites_sent"))
        });
      });
    },
    _showInvites() {
      const dialog = (0, _application.getOwner)(this).lookup("service:dialog");
      dialog.confirm({
        confirmButtonClass: "btn-large btn-danger",
        confirmButtonLabel: "support_tickets.start.send_invites",
        didConfirm: () => {
          this._sendInvites(this.get("model.seller_email"), this.get("model.buyer_email"));
        },
        message: (0, _template.htmlSafe)(I18n.t("support_tickets.start.send_invites_msg"))
      });
      return false;
    },
    _showLogin() {
      this.showLogin();
    },
    _showLoginOrRegister(emails) {
      const dialog = (0, _application.getOwner)(this).lookup("service:dialog");
      dialog.confirm({
        confirmButtonClass: "btn-large btn-danger",
        confirmButtonLabel: "support_tickets.start.login_button",
        didConfirm: () => {
          this.showLogin();
        },
        cancelButtonClass: "btn-large btn-danger",
        cancelButtonLabel: "support_tickets.start.register_button",
        didCancel: () => {
          this.showCreateAccount();
        },
        message: (0, _template.htmlSafe)(I18n.t("support_tickets.start.login_or_register", {
          email: emails[0]
        }))
      });
      return false;
    }
  }, [["method", "updateUsername", [(0, _decorators.observes)("model.target_usernames")]], ["method", "title", [(0, _decorators.default)("currentUser", "titleArgs")]], ["method", "titleArgs", [(0, _decorators.default)("currentUser")]], ["method", "nextDisabled", [(0, _decorators.default)("loading", "currentUser", "model.target_username", "model.seller_email", "model.buyer_email")]]]));
});