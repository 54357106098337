define("discourse/plugins/swapd-support-tickets/discourse/components/step-advanced", ["exports", "discourse-common/utils/decorators", "@ember/object/computed", "@ember/utils", "@ember/component", "@ember/application", "discourse/plugins/swapd-support-tickets/discourse/lib/support-ticket-constants", "discourse/lib/ajax-error", "discourse/lib/url", "discourse/lib/ajax", "discourse/plugins/swapd-support-tickets/discourse/lib/support-ticket-helper", "@ember/template", "@ember/service"], function (_exports, _decorators, _computed, _utils, _component, _application, _supportTicketConstants, _ajaxError, _url, _ajax, _supportTicketHelper, _template, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function i18n(stepType, stepName, type) {
    const strs = ["support_tickets", "start", "steps", stepType, stepName, type];
    return (0, _template.htmlSafe)(I18n.t(strs.join(".")));
  }
  var _default = _exports.default = _component.default.extend(dt7948.p({
    loading: false,
    isSeller: (0, _computed.equal)("model.seller_or_buyer", "seller"),
    isBuyer: (0, _computed.equal)("model.seller_or_buyer", "buyer"),
    isPrice: (0, _computed.equal)("stepName", "price"),
    isText: (0, _computed.equal)("field.t", _supportTicketConstants.TEXT),
    isEditor: (0, _computed.equal)("field.t", _supportTicketConstants.COMP),
    isSelect: (0, _computed.equal)("field.t", _supportTicketConstants.SEL),
    isTerms: (0, _computed.equal)("field.t", _supportTicketConstants.TERM),
    dialog: (0, _service.inject)(),
    value: {
      get() {
        const stepName = this.get("stepName");
        return this.get(`model.${stepName}`);
      },
      set(val) {
        const stepName = this.get("stepName");
        const isPrice = this.get("isPrice");
        let val2 = val;
        if (this.get("stepName") == "payment_method") {
          if (val == 'TrustAp') {
            if (this.sellerIsTrustapFullUser() == false) {
              var i18nPath;
              if (this.isSeller) {
                i18nPath = "support_tickets.start.steps.seller_not_trustap_message.";
              } else {
                i18nPath = "support_tickets.start.steps.buyer_not_trustap_message.";
              }
              this.dialog.confirm({
                message: (0, _template.htmlSafe)(I18n.t(i18nPath + "message")),
                confirmButtonClass: 'btn-danger',
                confirmButtonLabel: i18nPath + "ok_button",
                cancelButtonLabel: i18nPath + "cancel_button",
                didConfirm: () => {
                  if (this.isSeller) {
                    // for seller, set up a trustap account
                    window.location.href = '/trustap/openid-start';
                    return;
                  } else {
                    // for buyer, reset the payment method
                    val2 = "";
                    this.set(`model.${stepName}`, val2);
                    this.notifyPropertyChange("value");
                    return val2;
                  }
                },
                didCancel: () => {
                  if (this.isSeller) {
                    // for seller, reset the payment method
                    val2 = "";
                    this.set(`model.${stepName}`, val2);
                    this.notifyPropertyChange("value");
                    return val2;
                  } else {
                    // for buyer, cancel the ticket
                    _url.default.routeTo("/");
                    return;
                  }
                }
              });
            }
          }
        }
        if (isPrice) {
          const isValidNumber = /^\d+(\.\d{0,2})?$/.test(val2);
          if (val2 != "" && !isValidNumber) {
            // put back previous val
            val2 = this.get(`model.${stepName}`) || '';
          }
        }
        this.set(`model.${stepName}`, val2);
        this.notifyPropertyChange("value");
        return val2;
      }
    },
    title(stepType, stepName) {
      return i18n(stepType, stepName, "title");
    },
    helpMsg(stepType, stepName) {
      return i18n(stepType, stepName, "help_msg");
    },
    stepType(isSeller, isBuyer, type) {
      if (type == "other") {
        return "other";
      }
      if (isSeller && type === "service") {
        return "seller_service";
      }
      return "normal";
    },
    stepName(steps, step) {
      return steps[step - 3];
    },
    steps(stepType) {
      return Object.keys(_supportTicketConstants.STEPS[stepType]);
    },
    field(stepType, stepName) {
      return _supportTicketConstants.STEPS[stepType][stepName];
    },
    nextDisabled(value, optional, isPrice, loading) {
      if (loading) return true;
      if (optional) return false;
      if (isPrice) {
        return value <= 0 || /^\d+(\.\d{2})?$/.test(value) == false;
      }
      return (0, _utils.isBlank)(value) || !!!value;
    },
    nextLabel(isTerms) {
      return `support_tickets.start.${isTerms ? "checkout" : "next"}`;
    },
    actions: {
      next() {
        if (this.get("nextDisabled")) return;
        if (this.get("isPrice")) return this.handlePrice();
        if (this.get("isTerms")) return this.handleTerms();
        this.nextStep();
      }
    },
    nextStep() {
      const newStep = this.get("step") + 1;
      this.set("step", newStep);
      this.notifyPropertyChange("options");
    },
    sellerVipLevel() {
      return this.isSeller ? this.currentUser.get("vip_level") : this.get("model.target_vip_level");
    },
    lowestVipLevel() {
      return Math.min(this.currentUser.get("vip_level"), this.get("model.target_vip_level"));
    },
    sellerIsTrustapFullUser() {
      return this.isSeller ? this.currentUser.get("trustap_full_user") : this.get("model.target_trustap_full_user");
    },
    options(o) {
      if (this.get("stepName") == "payment_method") {
        const allowedPaymentMethods = this.get("model.allowed_payment_methods").split('|');
        return o.filter(payment => allowedPaymentMethods.includes(payment.value));
      }
      return o;
    },
    handlePrice() {
      const proceed = () => {
        this.nextStep();
      };
      const showLimit = (0, _supportTicketHelper.isShowLimit)(this.get("value"), this.get("model.payment_method").toLowerCase(), this.sellerVipLevel(), this.get("model.type"), proceed);
      if (!showLimit) {
        this.nextStep();
      }
    },
    handleTerms() {
      this.set("loading", true);
      const data = {};
      (0, _supportTicketConstants.modelKeys)().forEach(k => {
        data[k] = this.get(`model.${k}`);
      });
      if (this.get("topic")) {
        data.from_topic_id = this.get("topic.id");
      }
      (0, _ajax.ajax)("/start", {
        type: "POST",
        data: data
      }).then(result => {
        if (result.success) {
          var msg = (0, _template.htmlSafe)(I18n.t("support_tickets.start.steps.success_msg"));
        } else {
          var msg = (0, _template.htmlSafe)(I18n.t("support_tickets.start.steps." + result.msg, {
            username: this.get("model.target_username")
          }));
        }
        const dialog = (0, _application.getOwner)(this).lookup("service:dialog");
        dialog.alert({
          didConfirm: () => {
            const target = this.get("topic.lastPostUrl") || "/";
            _url.default.routeTo(target);
          },
          message: msg
        });
      }).catch(_ajaxError.popupAjaxError).finally(() => this.set("loading", false));
    }
  }, [["field", "value", [(0, _decorators.default)("isPrice", "stepName")]], ["method", "title", [(0, _decorators.default)("stepType", "stepName")]], ["method", "helpMsg", [(0, _decorators.default)("stepType", "stepName")]], ["method", "stepType", [(0, _decorators.default)("isSeller", "isBuyer", "model.type")]], ["method", "stepName", [(0, _decorators.default)("steps", "step")]], ["method", "steps", [(0, _decorators.default)("stepType")]], ["method", "field", [(0, _decorators.default)("stepType", "stepName")]], ["method", "nextDisabled", [(0, _decorators.default)("value", "field.opt", "isPrice", "loading")]], ["method", "nextLabel", [(0, _decorators.default)("isTerms")]], ["method", "options", [(0, _decorators.default)("field.o")]]]));
});