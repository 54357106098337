define("discourse/plugins/swapd-support-tickets/discourse/components/start-step", ["exports", "discourse-common/utils/decorators", "@ember/component"], function (_exports, _decorators, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    classNames: ["start"],
    showProgress: false,
    actions: {
      nextStep() {
        this.sendAction("next");
      },
      back() {
        const step = this.get("step");
        this.set("step", step - 1);
      }
    },
    backEnabled(step) {
      return step > 0;
    }
  }, [["method", "backEnabled", [(0, _decorators.default)("step")]]]));
});