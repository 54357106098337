define("discourse/plugins/swapd-support-tickets/discourse/initializers/tickets-sidebar", ["exports", "discourse/lib/plugin-api", "I18n", "discourse-common/utils/decorators", "@ember/object"], function (_exports, _pluginApi, _I18n, _decorators, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const CHANNEL = "/support-tickets/open-tickets-count";
  class RouteInfoHelper {
    constructor(router, url) {
      this.routeInfo = router.recognize(url);
    }
    get route() {
      return this.routeInfo.name;
    }
    get models() {
      return this.#getParameters;
    }
    get query() {
      return this.routeInfo.queryParams;
    }
    /**
     * Extracted from https://github.com/emberjs/rfcs/issues/658
     * Retrieves all parameters for a `RouteInfo` object and its parents in
     * correct oder, so that you can pass them to e.g.
     * `transitionTo(routeName, ...params)`.
     */
    get #getParameters() {
      let allParameters = [];
      let current = this.routeInfo;
      do {
        const {
          params,
          paramNames
        } = current;
        const currentParameters = paramNames.map(n => params[n]);
        allParameters = [...currentParameters, ...allParameters];
      } while (current = current.parent);
      return allParameters;
    }
  }
  var _default = _exports.default = {
    name: "tickets-sidebar",
    initialize(container) {
      (0, _pluginApi.withPluginApi)("1.3.0", api => {
        api.addSidebarSection((BaseCustomSidebarSection, BaseCustomSidebarSectionLink) => {
          const SidebarGenericLink = class extends BaseCustomSidebarSectionLink {
            constructor(args) {
              super(...arguments);
              this.args = args;
              this.router = Discourse.__container__.lookup('service:router');
              if (args.href) {
                this.routeInfoHelper = new RouteInfoHelper(this.router, args.href);
              }
            }
            willDestroy() {}
            static #_ = (() => dt7948.n(this.prototype, "willDestroy", [_decorators.bind]))();
            get name() {
              return this.args.title.toLowerCase().replace(/[^\w\s]|_/g, '').replace(/\s+/g, '-').replace(/^-+|-+$/g, '');
            }
            get classNames() {
              const classes = ['section-link-' + this.args.title.toLowerCase().replace(/[^\w\s]|_/g, '') // Remove special characters and underscores
              .replace(/\s+/g, '-') // Replace spaces with hyphens
              .replace(/^-+|-+$/g, '') // Remove leading and trailing hyphens
              ];
              return classes.join(" ");
            }
            get route() {
              return this.routeInfoHelper.route;
            }
            get models() {
              return this.routeInfoHelper.models;
            }
            get text() {
              return this.args.title;
            }
            get prefixType() {
              return "icon";
            }
            get prefixValue() {
              return this.args.icon;
            }
            get prefixColor() {
              return "";
            }
            get title() {
              return this.args.title;
            }
            get prefixBadge() {
              return "";
            }
            get suffixType() {
              return "icon";
            }
            get suffixValue() {
              return "";
            }
            get suffixCSSClass() {
              return "";
            }
          };
          const SidebarTicketsSectionLink = class extends BaseCustomSidebarSectionLink {
            constructor(_ref) {
              let {
                currentUser
              } = _ref;
              super(...arguments);
              this.currentUser = currentUser;
              if (currentUser) {
                this.messageBus = Discourse.__container__.lookup('service:message-bus');
                this.messageBus.subscribe(CHANNEL, data => {
                  if (data.user_id == this.currentUser.get("id")) {
                    this.currentUser.set("open_tickets_count", data.count);
                  }
                });
              }
            }
            willDestroy() {
              if (this.messageBus) {
                this.messageBus.unsubscribe(CHANNEL);
              }
            }
            static #_ = (() => dt7948.n(this.prototype, "willDestroy", [_decorators.bind]))();
            get name() {
              return 'name'; //dasherize(slugifyChannel(this.channel));
            }
            get classNames() {
              const classes = [];
              return classes.join(" ");
            }
            get route() {
              return "userPrivateMessages.tickets";
            }
            get models() {
              return [this.currentUser.get("username_lower")];
            }
            get text() {
              if (this.currentUser.open_tickets_count > 0) {
                return _I18n.default.t("support_tickets.sidebar.ticketlink_count", {
                  count: this.currentUser.open_tickets_count
                });
              } else {
                return _I18n.default.t("support_tickets.sidebar.ticketlink");
              }
            }
            static #_2 = (() => dt7948.n(this.prototype, "text", [(0, _object.computed)("currentUser.open_tickets_count")]))();
            get prefixType() {
              return "icon";
            }
            get prefixValue() {
              return "shopping-cart";
            }
            get prefixColor() {
              return "";
            }
            get title() {
              return _I18n.default.t("support_tickets.sidebar.ticketlink_title");
            }
            get prefixBadge() {
              return "";
            }
            get suffixType() {
              return "icon";
            }
            get suffixValue() {
              return this.currentUser.open_tickets_count > 0 ? "circle" : "";
            }
            static #_3 = (() => dt7948.n(this.prototype, "suffixValue", [(0, _object.computed)("currentUser.open_tickets_count")]))();
            get suffixCSSClass() {
              return "unread";
            }
          };
          const SidebarTicketsSection = class extends BaseCustomSidebarSection {
            constructor() {
              super(...arguments);
              if (container.isDestroyed) {
                return;
              }
              this.currentUser = Discourse.__container__.lookup('current-user:main');
              this.router = container.lookup("service:router");
            }
            get sectionLinks() {
              var items = [];
              if (this.currentUser) {
                items.push(new SidebarTicketsSectionLink({
                  currentUser: this.currentUser
                }));
              }
              var genericItems = [new SidebarGenericLink({
                icon: 'crown',
                title: _I18n.default.t("support_tickets.sidebar.topsellers"),
                href: '/report'
              }), new SidebarGenericLink({
                icon: 'bullhorn',
                title: _I18n.default.t("support_tickets.sidebar.promote_your_listings"),
                href: '/t/418181'
              }), new SidebarGenericLink({
                icon: 'fab-btc',
                title: "Buy Crypto",
                href: '/crypto'
              }), new SidebarGenericLink({
                icon: 'sync',
                title: "Swap Crypto",
                href: '/swap'
              })];
              items.push(...genericItems);
              if (this.currentUser) {
                var link = '/u/' + this.currentUser.username + '/invited/earnings';
                items.push(new SidebarGenericLink({
                  icon: 'money-bill-alt',
                  title: "Earnings Panel",
                  href: link
                }));
              }
              items.push(new SidebarGenericLink({
                icon: 'info',
                title: "Payment Methods Status",
                href: '/t/1085668'
              }));
              return items;
            }
            get name() {
              return "transactions";
            }
            get text() {
              return _I18n.default.t("support_tickets.sidebar.section_name");
            }
            get actions() {
              return [];
            }
            get actionsIcon() {
              return "pencil-alt";
            }
            get links() {
              return this.sectionLinks;
            }
            get displaySection() {
              return true;
            }
          };
          return SidebarTicketsSection;
        });
      });
    }
  };
});