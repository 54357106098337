define("discourse/plugins/swapd-support-tickets/discourse/controllers/user-activity-report", ["exports", "discourse-common/utils/decorators", "discourse/lib/ajax", "@ember/controller", "discourse/plugins/swapd-support-tickets/discourse/components/modal/rank-edit-modifiers", "@ember/application", "@glimmer/tracking", "@ember/object"], function (_exports, _decorators, _ajax, _controller, _rankEditModifiers, _application, _tracking, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class UserActivityReportController extends _controller.default {
    updateSales(v) {
      (0, _object.set)(this.model, 'sales_modifier', v);
    }
    updatePurchases(v) {
      (0, _object.set)(this.model, 'purchases_modifier', v);
    }
    fees(sales) {
      return Math.floor((sales || 0) / 100 * 8.5);
    }
    static #_ = (() => dt7948.n(this.prototype, "fees", [(0, _decorators.default)("model.sales")]))();
    _updatePrivacy() {
      if (!this.model.can_edit_privacy) {
        return;
      }
      (0, _ajax.ajax)(`/report/u/${this.model.user.id}/privacy`, {
        type: "POST",
        data: {
          is_public: this.model.is_public
        }
      });
    }
    static #_2 = (() => dt7948.n(this.prototype, "_updatePrivacy", [(0, _decorators.observes)("model.is_public")]))();
    who(id1, id2, username) {
      if (id1 == id2) {
        return "you";
      }
      return username;
    }
    static #_3 = (() => dt7948.n(this.prototype, "who", [(0, _decorators.default)("model.user.id", "currentUser.id", "model.user.username")]))();
    editModifiers() {
      let modal = (0, _application.getOwner)(this).lookup("service:modal");
      const result = modal.show(_rankEditModifiers.default, {
        model: {
          updateSales: v => this.updateSales(v),
          updatePurchases: v => this.updatePurchases(v),
          userId: this.model.user.id,
          sales: this.model.sales_modifier,
          purchases: this.model.purchases_modifier
        }
      });
    }
    static #_4 = (() => dt7948.n(this.prototype, "editModifiers", [_object.action]))();
  }
  _exports.default = UserActivityReportController;
});