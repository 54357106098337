define("discourse/plugins/swapd-support-tickets/discourse/routes/start", ["exports", "discourse/plugins/swapd-support-tickets/discourse/lib/support-ticket-constants", "discourse/routes/discourse", "@glimmer/tracking"], function (_exports, _supportTicketConstants, _discourse, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class BaseModel {
    static #_ = (() => dt7948.g(this.prototype, "data", [_tracking.tracked], function () {
      return {};
    }))();
    #data = (() => (dt7948.i(this, "data"), void 0))();
    constructor() {
      let initialKeys = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      initialKeys.forEach(key => this.data[key] = null);
    }
  }
  function createModel() {
    return new BaseModel((0, _supportTicketConstants.modelKeys)());
  }
  var _default = _exports.default = _discourse.default.extend({
    titleToken() {
      return I18n.t("support_tickets.start.title.page");
    },
    resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({
          step: 0,
          model: createModel()
        });
      }
    },
    setupController(controller, model) {
      this._super(controller, model);
      controller.set("step", 0);
    },
    model() {
      return createModel();
    }
  });
});