define("discourse/plugins/swapd-support-tickets/discourse/components/modal/send-invite-modal", ["exports", "@ember/component", "I18n", "@ember/object", "discourse/lib/utilities", "@glimmer/tracking", "discourse/lib/ajax", "@ember/application", "@ember/template", "@ember/template-factory"], function (_exports, _component, _I18n, _object, _utilities, _tracking, _ajax, _application, _template, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    class="show-invite-modal"
    id="show-invite-modal"
    @flash={{this.flash}}
    @title={{i18n "support_tickets.start.send_invite_title"}}
  >
  <:body>
    <p>{{ this.msg}}</p>
  <Input
      placeholder={{i18n "support_tickets.start.enter_email"}}
      @value={{this.value}} />
  </:body>
    <:footer>
      <DButton
          @action={{action "inviteUser"}}
          @class="btn-danger btn-large invite"
          @label={{"support_tickets.start.send_invite_invite_button"}}
          @disabled={{this.btnDisabled}}
      />
      <DButton
          @action={{@closeModal}}
          @class="btn-large"
          @label={{"support_tickets.start.send_invite_cancel_button"}}
      />
    </:footer>
  
  </DModal>
  */
  {
    "id": "7CDZbRfn",
    "block": "[[[8,[39,0],[[24,0,\"show-invite-modal\"],[24,1,\"show-invite-modal\"]],[[\"@flash\",\"@title\"],[[30,0,[\"flash\"]],[28,[37,1],[\"support_tickets.start.send_invite_title\"],null]]],[[\"body\",\"footer\"],[[[[1,\"\\n  \"],[10,2],[12],[1,[30,0,[\"msg\"]]],[13],[1,\"\\n\"],[8,[39,2],[[16,\"placeholder\",[28,[37,1],[\"support_tickets.start.enter_email\"],null]]],[[\"@value\"],[[30,0,[\"value\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"\\n    \"],[8,[39,3],null,[[\"@action\",\"@class\",\"@label\",\"@disabled\"],[[28,[37,4],[[30,0],\"inviteUser\"],null],\"btn-danger btn-large invite\",\"support_tickets.start.send_invite_invite_button\",[30,0,[\"btnDisabled\"]]]],null],[1,\"\\n    \"],[8,[39,3],null,[[\"@action\",\"@class\",\"@label\"],[[30,1],\"btn-large\",\"support_tickets.start.send_invite_cancel_button\"]],null],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\"],false,[\"d-modal\",\"i18n\",\"input\",\"d-button\",\"action\"]]",
    "moduleName": "discourse/plugins/swapd-support-tickets/discourse/components/modal/send-invite-modal.hbs",
    "isStrictMode": false
  });
  class SendInviteModal extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "flash", [_tracking.tracked], function () {
      return null;
    }))();
    #flash = (() => (dt7948.i(this, "flash"), void 0))();
    value = "";
    static #_2 = (() => dt7948.g(this.prototype, "btnDisabled", [_tracking.tracked], function () {
      return false;
    }))();
    #btnDisabled = (() => (dt7948.i(this, "btnDisabled"), void 0))();
    msg = "";
    init() {
      super.init(...arguments);
      var key = this.userNotFound ? "user_not_found" : "send_invite_msg";
      this.msg = _I18n.default.t(`support_tickets.start.${key}`);
    }
    showError(err) {
      this.flash = err ? err : "An error has occurred";
    }
    inviteUser() {
      if (!(0, _utilities.emailValid)(this.value)) {
        this.showError("Invalid email");
        return false;
      } else {
        this.flash = null;
      }
      this.btnDisabled = true;
      (0, _ajax.ajax)("/invites", {
        type: "POST",
        data: {
          email: this.value
        }
      }).then(result => {
        if (result.invite_key) {
          this.closeModal();
          const dialog = (0, _application.getOwner)(this).lookup("service:dialog");
          dialog.alert({
            message: (0, _template.htmlSafe)(_I18n.default.t("support_tickets.start.invite_sent"))
          });
        } else {
          this.showError();
        }
      }).catch(e => this.showError(e?.jqXHR?.responseJSON?.errors[0])).finally(() => this.btnDisabled = false);
      return true;
    }
    static #_3 = (() => dt7948.n(this.prototype, "inviteUser", [_object.action]))();
  }
  _exports.default = SendInviteModal;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, SendInviteModal);
});