define("discourse/plugins/swapd-support-tickets/discourse/controllers/report", ["exports", "discourse-common/utils/decorators", "@ember/controller"], function (_exports, _decorators, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend(dt7948.p({
    tables: ["sales", "purchases"],
    periods: ["last_365", "last_30", "last_7", "last_1", "all_time"],
    yourRankUrl(username) {
      if (!username) return;
      return `/u/${username}/activity/report`;
    }
  }, [["method", "yourRankUrl", [(0, _decorators.default)("currentUser.username")]]]));
});