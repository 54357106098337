define("discourse/plugins/swapd-support-tickets/discourse/components/ticket-status", ["exports", "discourse-common/utils/decorators", "@ember/component", "I18n"], function (_exports, _decorators, _component, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.getTagList = getTagList;
  let tagListObj;
  function getTagList() {
    if (tagListObj) return tagListObj;
    const tagList = JSON.parse(_I18n.default.t("support_tickets.tag_list")).map(t => {
      t.tag = t.tag.toLowerCase();
      return t;
    });
    tagListObj = tagList;
    return tagListObj;
  }
  var _default = _exports.default = _component.default.extend(dt7948.p({
    shouldDisplay(isPrivateMessage, ticketCreated, closed, tag) {
      return isPrivateMessage && ticketCreated && !closed && tag;
    },
    selectedTag(shouldDisplay, tag) {
      if (!shouldDisplay) return;
      const tagList = getTagList();
      return tagList.findBy("tag", tag);
    }
  }, [["method", "shouldDisplay", [(0, _decorators.default)("topic.isPrivateMessage", "topic.support_ticket_created", "topic.closed", "topic.ticket_tag")]], ["method", "selectedTag", [(0, _decorators.default)("shouldDisplay", "topic.ticket_tag")]]]));
});