define("discourse/plugins/swapd-support-tickets/discourse/controllers/start", ["exports", "discourse-common/utils/decorators", "@ember/string", "@ember/controller"], function (_exports, _decorators, _string, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend(dt7948.p({
    step: 0,
    topic: null,
    componentName(step) {
      const steps = (0, _string.w)("zero one two advanced");
      if (step < 3) {
        return "step-" + steps[step];
      }
      return "step-advanced";
    }
  }, [["method", "componentName", [(0, _decorators.default)("step")]]]));
});